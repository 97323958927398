export const getMappedPages = ({ pages, sectionActions = (page) => page }) => {
  const pagesList = Object.entries(pages).reduce(
    (newPagesList, [displayName, page]) => {
      page.displayName = displayName;
      return [...newPagesList, page];
    },
    []
  );

  return pagesList.map(sectionActions);
};

export const getPagesWithSections = (pagesBySection) => {
  return Object.entries(pagesBySection).reduce(
    (newPagesList, [section, sectionPages]) => {
      const pagesList = getMappedPages(sectionPages);
      return [
        ...newPagesList,
        ...pagesList.map((page) => {
          return { section: section, PageContent: page };
        }),
      ];
    },
    []
  );
};

export const descendantProperty = (object, fieldString, defaultValue) => {
  if (!object) {
    return defaultValue;
  }
  try {
    return fieldString
      .split('.')
      .reduce((iterateObject, field) => iterateObject[field], object);
  } catch (e) {
    return defaultValue;
  }
};

export const isSelectOption = (value, options) => {
  return new Set(options).has(value);
};

export const formatDataTestId = (dataTestId) => {
  return dataTestId.replace(/ /g, '');
};

// eslint-disable-next-line no-extend-native
Object.defineProperty(String.prototype, 'capitalize', {
  value: function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
  },
  enumerable: false,
  configurable: true,
});

export const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const clamp = (value, min, max) => {
  return Math.min(Math.max(value, min), max);
};

export const cleanEmpty = (obj) => {
  if (Array.isArray(obj)) {
    return obj
      .map((v) => (v && typeof v === 'object' ? cleanEmpty(v) : v))
      .filter((v) => !(v == null));
  } else {
    return Object.entries(obj)
      .map(([k, v]) => [k, v && typeof v === 'object' ? cleanEmpty(v) : v])
      .reduce((a, [k, v]) => (v == null ? a : ((a[k] = v), a)), {});
  }
};

const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const retryFunction = async (fn, maxRetries = 3, delay = 10000) => {
  for (let retryCount = 0; retryCount < maxRetries; retryCount++) {
    try {
      return await fn(); // If successful, return the result
    } catch (error) {
      if (retryCount < maxRetries - 1) {
        await sleep(delay * (retryCount + 1)); // If not successful and retries are left, wait and then retry
      } else {
        throw error; // If all retries fail, throw the last error
      }
    }
  }
};
