import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import {
  Control,
  FieldValues,
  useController,
  UseFormTrigger,
} from 'react-hook-form';

import { Input } from 'src/common';
import {
  currencyTransform,
  identityTransform,
  roundDown,
  roundUp,
  truncate,
} from 'src/lib/transforms';
import { limitations } from 'src/lib/enums';
import { twMerge } from 'src/lib/mergeTailwind';
import { InputProps } from '../Input/Input';
import { Rules } from 'src/lib/type';

export type RoundedType = 'roundUp' | 'roundDown' | 'truncate';

type NumericInputProps = {
  name: string;
  control: Control & { getFieldError: (name: string) => string };
  rules?: Rules;
  defaultValue?: string | number;
  label?: string;
  error?: string;
  trigger?: UseFormTrigger<FieldValues>;
  min?: number;
  max?: number;
  step?: number;
  containerClassName?: string;
  transform?: {
    input: (value: string | number) => string | number;
    output: (value: string | number) => string | number;
  };
  format?: {
    input: (value: number) => string;
    output: (value: string) => number;
  };
  helpTextTitle?: string;
  helpTextDescription?: string;
  roundedType?: RoundedType;
  round?: boolean;
} & InputProps;

export type RefNumericInput = {
  handleOnChange: (value: string | number) => void;
};

const NumericInput = React.forwardRef<RefNumericInput, NumericInputProps>(
  (
    {
      id,
      name,
      type,
      control,
      rules = { required: false, validate: () => true },
      defaultValue,
      label,
      error: errorField,
      trigger,
      min: minInput = 0,
      max: maxInput = limitations.INT_MAX,
      step = 1,
      containerClassName,
      transform = identityTransform,
      format = currencyTransform,
      helpTextTitle,
      helpTextDescription,
      roundedType = 'roundDown',
      round = true,
      value,
      ...inputProps
    },
    ref
  ) => {
    const error =
      !errorField && control?.getFieldError && control.getFieldError(name)
        ? control.getFieldError(name)
        : errorField;

    const customValidate = rules?.validate;

    const [min, setMin] = useState(minInput);
    const [max, setMax] = useState(maxInput);

    const numericInputRef = useRef<HTMLInputElement | null>(null);

    rules.validate = (value: string | number) => {
      let minValue = min;
      if (!round && min !== minInput) {
        minValue = minInput;
      }
      const v = Number(transform.input(value));
      if (minValue !== undefined && v < minValue) {
        return `The minimum value is ${currencyTransform.input(minValue)}.`;
      } else if (max !== undefined && v > Number(max)) {
        return `The maximum value is ${currencyTransform.input(max)}.`;
      } else if (step !== undefined && v % step !== 0 && round) {
        return `Must be a multiple of ${currencyTransform.input(step)}.`;
      }

      return customValidate ? customValidate(value) : true;
    };

    const { field } = useController({
      name,
      rules,
      control,
      defaultValue,
    });

    useEffect(() => {
      if (minInput % step === 0 || !round) {
        setMin(minInput);
      } else {
        setMin(minInput - (minInput % step) + step);
      }
    }, [minInput, round, step]);

    useEffect(() => {
      if (maxInput % step === 0) {
        setMax(maxInput);
      } else {
        setMax(maxInput - (maxInput % step));
      }
    }, [maxInput, step]);

    useImperativeHandle(ref, () => ({
      handleOnChange: (value: string | number) => {
        let changeTo;
        if (round) {
          changeTo = handleRound(transform.output(value));
        } else {
          changeTo = transform.output(value);
        }
        field.onChange(changeTo);
      },
    }));
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'ArrowUp') {
        increase(e);
      }
      if (e.key === 'ArrowDown') {
        decrease(e);
      }
    };

    const handleRound = (value: number) => {
      if (roundedType === 'roundUp') {
        return roundUp(value, step);
      }
      if (roundedType === 'roundDown') {
        return roundDown(value, step);
      }
      if (roundedType === 'truncate') {
        return truncate(value, step);
      }
      return value;
    };
    const handleChange = (val: number) => {
      let changeTo;
      if (round) {
        changeTo = handleRound(val);
      } else {
        changeTo = val;
      }
      if (changeTo) {
        if (changeTo < min) {
          changeTo = min;
        } else if (changeTo > max) {
          changeTo = max;
        }
        field.onChange(transform.output(changeTo));
      }
    };

    const increase = (e: React.KeyboardEvent<HTMLInputElement>) => {
      e.preventDefault();
      handleChange(Number(transform.input(field.value)) + step);
      numericInputRef.current?.focus();
    };

    const decrease = (e: React.KeyboardEvent<HTMLInputElement>) => {
      e.preventDefault();
      handleChange(Number(transform.input(field.value)) - step);
      numericInputRef.current?.focus();
    };

    const handleValidation = () => {
      const value = field.value;
      if (!round) {
        if (trigger) {
          trigger();
        }
        return;
      }

      if (min > value) {
        field.onChange(min);
        return;
      }
      if (max < value) {
        field.onChange(max);
        return;
      }

      if (value % step === 0) {
        field.onChange(value);
      } else {
        field.onChange(value - (value % step) + step);
      }
    };

    return (
      <div className={twMerge(containerClassName, 'flex items-start')}>
        <Input
          id={name}
          containerClassName="w-full flex flex-col"
          type="text"
          label={label}
          min={min}
          max={max}
          value={field.value ? format.input(transform.input(field.value)) : ''}
          step={step}
          error={error}
          helpTextTitle={helpTextTitle}
          helpTextDescription={helpTextDescription}
          onChange={(e) => {
            const formatless = format.output(e.target.value);
            const transformed = transform.output(formatless);
            field.onChange(transformed);
          }}
          onKeyDown={handleKeyDown}
          onBlur={handleValidation}
          ref={(el) => {
            field.ref(el);
            numericInputRef.current = el;
          }}
          filter="decimal"
          {...inputProps}
        />
      </div>
    );
  }
);

export default NumericInput;
