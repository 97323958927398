import ReactDOM from 'react-dom';
import { usePortal } from 'src/lib/hooks';
import HeaderContent from './HeaderContent';

type HeaderProps = {
  displayConfetti: boolean;
};

const Header: React.FC<HeaderProps> = (props) => {
  const target = usePortal('viewport-header');
  return target && ReactDOM.createPortal(<HeaderContent {...props} />, target);
};

export default Header;
