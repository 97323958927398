import loadable, { type LoadableComponent } from '@loadable/component';

const florida: Record<string, LoadableComponent<unknown>> = {
  ho3: loadable(() => import('./florida/ho3')),
  ho6: loadable(() => import('./florida/ho6')),
};

const products: Record<string, Record<string, LoadableComponent<unknown>>> = {
  florida,
};

export default products;
