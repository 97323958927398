import React from 'react';
import { Link } from 'react-router-dom';

import { BasicLayout, Card, Footer } from 'src/common';
import { useAuth0 } from 'src/lib/hooks';
type InvalidResumptionProps = {
  email: string;
};

const InvalidResumption = ({ email }: InvalidResumptionProps) => {
  const { logout } = useAuth0();

  const onSubmit = () => {
    logout();
  };

  return (
    <BasicLayout displayNavElements={false}>
      <Card
        icon="bummer"
        title="Bummer."
        subtitle={
          <>
            <p>We don’t have any quotes in progress for</p>
            <p>
              <span className="font-bold"> {email}</span>
            </p>
            <p className="text-violet pt-4 font-bold">
              Would you like to
              <Link to="/" className=" pl-1 underline">
                start a new quote
              </Link>
              ?
            </p>
          </>
        }
        contentClassName="mb-0"
      />
      <Footer>
        <Footer.Next
          onClick={onSubmit}
          rounded
          label="Get Started"
          hideIcon={true}
        />
      </Footer>
    </BasicLayout>
  );
};

export default InvalidResumption;
