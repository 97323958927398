import { useLocation } from 'react-router-dom';

const useQueryParams = <T>(): Record<string, string> => {
  const location = useLocation<T>();
  const searchString = location.search;
  const searchParams = new URLSearchParams(searchString);
  const result: Record<string, string> = {};

  for (const [key, value] of searchParams.entries()) {
    result[key] = value;
  }

  return result;
};

export default useQueryParams;
