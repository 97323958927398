import React from 'react';
import { twMerge } from 'src/lib/mergeTailwind';

type AnnouncementBarProps = {
  role: string;
  className: string;
  text: string;
  showLink?: boolean;
  textLink?: string;
  href?: string;
  classNameLink?: string;
};

const AnnouncementBar = ({
  role,
  className,
  text,
  showLink = false,
  textLink = '',
  href = '',
  classNameLink = '',
}: AnnouncementBarProps) => {
  return (
    <p
      role={role}
      className={twMerge(
        'flex items-center flex-col justify-center text-center p-2',
        'sm:flex-row',
        className
      )}
    >
      {text}
      {showLink && (
        <a
          href={href}
          className={classNameLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          {textLink}
        </a>
      )}
    </p>
  );
};

export default AnnouncementBar;
