import axios from 'axios';

import config from 'src/config';
import addReferralHandler from 'src/lib/addReferralHandler';
import store from 'src/lib/store';
const getHeaders = (options = {}, secure = false) => {
  const headers = { ...options.headers };

  if (config.slide.locked === 'true') {
    headers['client_id'] = config.slide.clientId;
    headers['client_secret'] = config.slide.clientSecret;
  }

  if (secure) {
    const token = store.getState().user?.token;
    headers['Authorization'] = `Bearer ${token}`;
  }

  return headers;
};

const mockApi = {
  putProfileMockCall: () =>
    new Promise((res) => {
      setTimeout(res, 3000);
    }),
  patchProfileMockCall: () =>
    new Promise((res) => {
      setTimeout(res, 3000);
    }),
  postInterestMockCall: () =>
    new Promise((res) => {
      setTimeout(res, 3000);
    }),
};
const secure = {
  get: (path, options = {}) =>
    addReferralHandler(
      api.get('/secure' + path, {
        ...options,
        headers: getHeaders(options, true),
      })
    ),
  post: (path, body, options = {}) =>
    addReferralHandler(
      api.post('/secure' + path, body, {
        ...options,
        headers: getHeaders(options, true),
      })
    ),
  put: (path, body, options = {}) =>
    addReferralHandler(
      api.put('/secure' + path, body, {
        ...options,
        headers: getHeaders(options, true),
      })
    ),
  patch: (path, body, options = {}) =>
    addReferralHandler(
      api.patch('/secure' + path, body, {
        ...options,
        headers: getHeaders(options, true),
      })
    ),
  delete: (path, options = {}) =>
    addReferralHandler(
      api.delete('/secure' + path, {
        ...options,
        headers: getHeaders(options, true),
      })
    ),
  ...mockApi,
};

const api = {
  get: (path, options = {}) =>
    addReferralHandler(
      axios.get(config.slide.baseUrl + path, {
        ...options,
        headers: getHeaders(options),
      })
    ),
  post: (path, body, options = {}) =>
    addReferralHandler(
      axios.post(config.slide.baseUrl + path, body, {
        ...options,
        headers: getHeaders(options),
      })
    ),
  put: (path, body, options = {}) =>
    addReferralHandler(
      axios.put(config.slide.baseUrl + path, body, {
        ...options,
        headers: getHeaders(options),
      })
    ),
  patch: (path, body, options = {}) =>
    addReferralHandler(
      axios.patch(config.slide.baseUrl + path, body, {
        ...options,
        headers: getHeaders(options),
      })
    ),
  delete: (path, options = {}) =>
    addReferralHandler(
      axios.delete(config.slide.baseUrl + path, {
        ...options,
        headers: getHeaders(options),
      })
    ),
  ...mockApi,
  secure: { ...secure },
};

api.fakeLoad = (data) =>
  new Promise((resolve) => {
    setTimeout(() => resolve({ data }), 1000);
  });

export { api };

const useApi = () => api;

export default useApi;
