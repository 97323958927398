import { Icon } from 'src/common';
import { currencyTransform } from 'src/lib/transforms';
import { twMerge } from 'src/lib/mergeTailwind';

type PremiumProps = {
  label: string;
  premium: number;
  onRefresh: React.MouseEventHandler<HTMLButtonElement>;
  className: string;
  refreshing?: boolean;
  needsRefresh?: boolean;
  disabled?: boolean;
  hideLabel?: boolean;
};

const Premium = ({
  label = 'Annual Premium',
  premium,
  onRefresh,
  className,
  refreshing = false,
  needsRefresh = false,
  disabled = false,
  hideLabel = false,
}: PremiumProps) => {
  const boxClasses =
    'flex flex-col justify-center items-center h-58px w-152px  mx-2 rounded-md ';

  if (needsRefresh || refreshing) {
    return (
      <button
        onClick={onRefresh}
        className={twMerge(
          'bg-curious-blue space-y-1 focus:outline-none focus:ring-2 focus:ring-mystic focus:ring-offset-2 focus:ring-offset-violet',
          boxClasses
        )}
      >
        <Icon
          name="redo"
          color="white"
          size="md"
          className={refreshing ? 'animate-spin' : ''}
        />
        {!hideLabel && (
          <span className="text-xs  text-white">Refresh Premium</span>
        )}
      </button>
    );
  }
  return (
    <div
      className={twMerge(
        'border border-violet bg-custom-gray',
        disabled ? 'bg-input-lighter' : 'bg-custom-gray',
        boxClasses
      )}
    >
      <span
        className={twMerge(
          disabled ? 'text-white' : 'text-black',
          'text-lg font-bold leading-6',
          className
        )}
      >
        {currencyTransform.input(premium)}
      </span>
      <span
        className={twMerge(disabled ? 'text-white' : 'text-black', 'text-xs ')}
      >
        {label}
      </span>
    </div>
  );
};

export default Premium;
