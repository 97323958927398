import React, { useEffect, useState } from 'react';

import { useModel } from 'src/lib/hooks';
import { PAGES, WIND_MIT } from 'src/products/shared/constants';
import { twMerge } from 'src/lib/mergeTailwind';
import { NextPage } from 'src/types/pages';

type FormSkipButtosProps = {
  WindMitigationType: string;
  nextPage: NextPage;
  isCompleted?: boolean;
  isFooter?: boolean;
  className?: string;
};

const FormSkipButton: React.FC<FormSkipButtosProps> = ({
  WindMitigationType,
  nextPage,
  isCompleted = false,
  isFooter = true,
  className = '',
}) => {
  const { skip } = useModel.session.dispatch();

  const [{ loading }, setState] = useState({
    loading: false,
    runEligibility: false,
  });

  const onClick = async () => {
    switch (WindMitigationType) {
      case WIND_MIT.UNIFORM:
        skip({
          [PAGES.UniformQuestionnairePage]: true,
          [PAGES.UniformUploadPage]: true,
        });
        break;
      case WIND_MIT.BUILDING:
        skip({
          [PAGES.BuildingQuestionnairePage]: true,
          [PAGES.BuildingUploadPage]: true,
        });
        break;
      default:
        break;
    }

    setState({
      loading: true,
      runEligibility: false,
    });
  };

  useEffect(() => {
    const onSkip = async () => {
      await nextPage({ waitProfileUpdate: true });
    };

    if (loading) {
      onSkip();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [WindMitigationType, nextPage, loading]);
  if (!isFooter) {
    return (
      <button className={twMerge('underline')} onClick={onClick}>
        skip this discount
      </button>
    );
  }

  return (
    <span
      role="button"
      className={twMerge('text-2sm text-violet underline', className)}
      onClick={onClick}
      color="blue-light"
      aria-hidden
    >
      {isCompleted ? 'Remove this form' : 'Skip this form for now'}
    </span>
  );
};

export default FormSkipButton;
