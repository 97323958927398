import React, { ComponentPropsWithRef } from 'react';
import { twMerge } from 'src/lib/mergeTailwind';

type LabelProps = {
  srOnly?: boolean;
} & ComponentPropsWithRef<'label'>;

const Label = ({
  srOnly = false,
  children,
  ...props
}: React.PropsWithChildren<LabelProps>) => (
  <label
    className={srOnly ? twMerge('sr-only', props.className) : props.className}
    {...props}
  >
    {children}
  </label>
);

export default Label;
