import React, { ReactNode } from 'react';
import { twMerge } from 'src/lib/mergeTailwind';
type ErrorProps = {
  children?: ReactNode;
  className?: string;
  containerClassName?: string;
};

const Error = ({ children, className, containerClassName }: ErrorProps) => (
  <div className={twMerge('px-2 pt-4 pb-6 w-full', containerClassName)}>
    <div
      role="alert"
      className={twMerge(
        'text-error bg-error-lighter max-w-md rounded-lg p-2 mx-auto text-center border-error border-2',
        className
      )}
    >
      {children}
    </div>
  </div>
);

export default Error;
