import ReactDOM from 'react-dom';
import { usePortal } from 'src/lib/hooks';

import LoadingPage, { LoadingPageProps } from './LoadingPage';

const LoadingContent = (props: LoadingPageProps) => {
  const target = usePortal('viewport-loading-content');
  return target && ReactDOM.createPortal(<LoadingPage {...props} />, target);
};

export default LoadingContent;
