import React, { ComponentPropsWithRef } from 'react';

import './Form.module.css';

type FormProps = ComponentPropsWithRef<'form'>;

const Form = (props: FormProps) => {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    props.onSubmit && props.onSubmit(e);
  };

  return (
    <form
      {...props}
      onSubmit={handleSubmit}
      onChange={props.onChange}
      noValidate
    >
      {props.children}
      <input type="submit" className="sr-only invisible h-0" />
    </form>
  );
};

export default Form;
