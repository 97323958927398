import React from 'react';
import { Redirect } from 'react-router-dom';
import states from 'src/lib/states';

import { useModel } from 'src/lib/hooks';
import products from 'src/products';
import { State } from 'src/products/products';

const Onboarding = () => {
  const { State, FormType } = useModel.quote();

  function convertState(State: State) {
    return states.US.find((state) => {
      return state.value === State;
    });
  }
  const stateName = convertState(State)?.name.toLowerCase();
  const productName = FormType.replace('-', '').toLowerCase();

  if (!stateName) {
    return <Redirect to="/" />;
  }

  const OnboardingContent = products?.[stateName]?.[productName];

  if (!OnboardingContent) {
    return <Redirect to="/" />;
  }

  return <OnboardingContent />;
};

export default Onboarding;
