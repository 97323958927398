import React, { ReactNode, useEffect, useMemo } from 'react';
import { Control, Controller, RefCallBack } from 'react-hook-form';

import { useResponsiveVariant } from 'src/lib/hooks';
import Icon from 'src/common/Icon';
import { Fieldset } from '..';
import { formatDataTestId } from 'src/lib/utils';
import { FieldSetVariant } from '../Fieldset/Fieldset';
import { twMerge } from 'src/lib/mergeTailwind';
import { IconName, IconSize } from '../Icon/Icon';
import { Responsive } from 'src/lib/hooks/hooks';

type LayoutRadioInput =
  | 'vertical'
  | 'custom'
  | 'horizontal'
  | 'inline'
  | 'windMit'
  | 'interview';

type RadioInputProps<T> = {
  name: string;
  label?: ReactNode;
  labelSrOnly?: boolean;
  control: Control;
  defaultValue?: T;
  fieldsetVariant?: FieldSetVariant;
  containerClassName?: string;
  radioInputClassName?: string;
  rules: {
    required?: string | boolean;
    validate?: (value: T) => string | boolean | undefined;
  };
  ariaControls?: string;
  layout?: Responsive<LayoutRadioInput>;
  optionsClassname?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  error?: string;
  iconSize?: IconSize;
  afterChange?: (value: T) => void;
};

const RadioInput = <T,>({
  name,
  label,
  labelSrOnly = false,
  control,
  defaultValue,
  fieldsetVariant = 'default',
  containerClassName,
  radioInputClassName,
  rules,
  ariaControls,
  children,
  layout = 'vertical',
  optionsClassname,
  fullWidth = false,
  disabled = false,
  error,
  iconSize = '4xl',
  afterChange = () => {},
}: React.PropsWithChildren<RadioInputProps<T>>) => {
  const responsiveLayout = useResponsiveVariant(layout);
  let effectiveLayout: LayoutRadioInput;
  if (layout === 'custom') {
    effectiveLayout = 'custom';
  } else {
    effectiveLayout = responsiveLayout;
  }
  const options = useMemo(
    () =>
      children && React.Children.count(children) > 0
        ? React.Children.map(children, (_child) => {
            if (_child && React.isValidElement(_child)) {
              return _child.props.value;
            }
            return null;
          })?.filter((value) => value !== null && value !== undefined)
        : [],
    [children]
  );
  // radio uses 'false' as a value, use validate instead required
  if (rules?.required) {
    const customError = rules.required;
    const customValidate = rules.validate;

    rules.validate = (v) => {
      const isValidValue =
        options && options.filter((value) => value === v).length > 0;
      if (isValidValue && v !== null && v !== undefined) {
        return customValidate ? customValidate(v) : true;
      }
      return customError;
    };

    rules.required = false;
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field: { onChange, value, ref } }) => (
        <Fieldset
          label={label}
          labelId={name + '_legend'}
          labelSrOnly={labelSrOnly}
          variant={fieldsetVariant}
          error={error}
          disabled={disabled}
        >
          <div
            className={twMerge(
              containerClassName ?? 'flex flex-col items-center justify-center'
            )}
          >
            <div
              className={twMerge(
                effectiveLayout === 'vertical' &&
                  'flex flex-wrap items-end justify-around w-full mt-3',
                effectiveLayout === 'horizontal' &&
                  'flex flex-wrap flex-col items-start space-y-4 mt-5',
                effectiveLayout === 'inline' &&
                  'flex flex-wrap items-center p-2 w-full max-w-full',
                effectiveLayout === 'custom' && 'flex flex-wrap w-full mt-3',
                effectiveLayout === 'windMit' &&
                  'inline-flex flex-col space-y-4',
                effectiveLayout === 'vertical' &&
                  (radioInputClassName ??
                    'space-y-8 sm:space-y-0 sm:space-x-2'),
                effectiveLayout === 'custom' &&
                  (radioInputClassName ?? 'space-y-0 sm:space-x-2'),
                fullWidth && 'max-w-full'
              )}
            >
              {React.Children.map(
                children,
                (_child, i) =>
                  React.isValidElement(_child) &&
                  React.cloneElement(
                    _child as React.ReactElement,
                    !_child.props.children
                      ? {
                          name: name,
                          id: name + i,
                          ariaControls,
                          onChange: (e: T) => {
                            onChange(e);
                            afterChange(e);
                          },
                          selectedValue: value,
                          layout: effectiveLayout,
                          error: error,
                          inputRef: i === 0 && ref,
                          disabled: _child.props.disabled || disabled,
                          iconSize: _child.props.iconSize || iconSize,
                          className: twMerge(
                            _child.props.className,
                            optionsClassname
                          ),
                        }
                      : {}
                  )
              )}
            </div>
          </div>
        </Fieldset>
      )}
    />
  );
};

type OptionProps<T> = {
  label: string;
  labelClassName?: string;
  value: T;
  disabled?: boolean;
  name?: string;
  id?: string;
  ariaControls?: string;
  onChange?: (event: T | undefined) => void;
  className?: string;
  selectedValue?: T;
  icon?: IconName;
  iconSize?: IconSize;
  subtitle?: string;
  layout?: LayoutRadioInput;
  error?: string;
  inputRef?: RefCallBack;
};

const Option = <T,>({
  label,
  labelClassName = '',
  value,
  disabled = false,
  name,
  id,
  ariaControls,
  onChange = () => {},
  className,
  selectedValue,
  icon,
  iconSize,
  subtitle,
  layout,
  error,
  inputRef,
}: OptionProps<T>) => {
  const isChecked = value === selectedValue;
  useEffect(() => {
    if (disabled && isChecked) {
      onChange(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  return (
    <label
      htmlFor={id}
      className={twMerge(
        disabled ? 'opacity-70 cursor-not-allowed' : 'cursor-pointer',
        layout !== 'windMit' && 'flex flex-col',
        (layout === 'vertical' || layout === 'horizontal') && 'items-center',
        layout === 'inline' && 'items-start pl-3 py-2 min-w-1/3 sm:min-w-1/6',
        layout === 'custom' && 'w-6/12 xs:w-141px overflow-hidden items-center',
        layout === 'windMit' && 'inline-flex',
        className
      )}
    >
      {icon && (
        <Icon
          name={icon}
          size={iconSize}
          color="none"
          className="my-3 h-auto"
        />
      )}

      <div
        className={twMerge(
          layout !== 'windMit' && 'flex items-center justify-center',
          (layout === 'vertical' || layout === 'custom') && 'flex-col',
          layout === 'windMit' && 'inline-flex space-x-4'
        )}
      >
        <input
          className={twMerge(
            'appearance-none m-1 h-4 w-4 min-w-1 border rounded-full',
            'focus:ring-2 ring-offset-2',
            'checked:bg-circle-violet bg-contain bg-center bg-no-repeat',
            error ? 'border-error  ring-error' : ' border-violet  ring-violet'
          )}
          id={id}
          name={name}
          disabled={disabled}
          onClick={() => onChange(value)}
          type="radio"
          defaultChecked={isChecked}
          aria-controls={ariaControls}
          aria-checked={isChecked ? 'true' : 'false'}
          data-testid={formatDataTestId(`${name}.${value}`)}
          {...(inputRef && { ref: inputRef })}
        />
        <span
          className={twMerge(
            'leading-5',
            !error &&
              layout !== 'interview' &&
              layout !== 'windMit' &&
              'text-violet',
            !error &&
              (layout === 'interview' || layout === 'windMit') &&
              'text-black font-bold',

            error && 'text-error',
            (layout === 'vertical' || layout === 'custom') &&
              'flex flex-col items-center pt-1.5',
            (layout === 'inline' || layout === 'horizontal') && 'pl-2',
            layout === 'inline' && 'text-xs',
            layout === 'interview' && 'px-2'
          )}
        >
          <span
            className={twMerge(
              layout === 'inline' ? isChecked && 'font-bold' : 'font-bold',
              layout === 'custom' ? 'text-center' : '',
              labelClassName
            )}
            style={{ whiteSpace: 'pre-line' }}
          >
            {label}
          </span>
          {subtitle && (
            <span
              className={twMerge(
                'leading-5',
                (layout === 'inline' || layout === 'horizontal') && 'pl-1.5'
              )}
            >
              {subtitle}
            </span>
          )}
        </span>
      </div>
    </label>
  );
};

const OtherComponent: React.FC<{ OtherComponent: ReactNode }> = ({
  OtherComponent,
}) => {
  return OtherComponent;
};

RadioInput.Option = Option;
RadioInput.OtherComponent = OtherComponent;

export default RadioInput;
