export type State = 'FL';
export type FormType = 'HO-3' | 'HO-6' | 'DP-3';

export const FormTypeFolderMap: Record<FormType, string> = {
  'DP-3': 'dp3',
  'HO-3': 'ho3',
  'HO-6': 'ho6',
};

const StateFullName: Record<State, string> = {
  FL: 'Florida',
};

export type Product = {
  FormType: FormType;
  State: State;
  FullState: string;
  PropertyType: 'home' | 'condo';
};

export const PRODUCTS: Record<string, Product> = {
  FLORIDA_HO3: {
    FormType: 'HO-3',
    State: 'FL',
    FullState: StateFullName.FL,
    PropertyType: 'home',
  },
  FLORIDA_HO6: {
    FormType: 'HO-6',
    State: 'FL',
    FullState: StateFullName.FL,
    PropertyType: 'condo',
  },
  FLORIDA_DP3: {
    FormType: 'DP-3',
    State: 'FL',
    FullState: StateFullName.FL,
    PropertyType: 'home',
  },
};
