import React, { useLayoutEffect, useState } from 'react';
import { BasicLayout, Footer, Button } from 'src/common';
import LoadingPage from 'src/common/LoadingContent/LoadingPage';

import { useAuth0 } from 'src/lib/hooks';

const Login = () => {
  const { completeLogin, logout } = useAuth0();
  const [error, setError] = useState<string>('');

  useLayoutEffect(() => {
    const login = async () => {
      try {
        await completeLogin();
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);

        setError('Failed to log you in.');
      }
    };
    login();
    // eslint-disable-next-line
  }, []);

  return !error ? (
    <LoadingPage text="Logging you in..." />
  ) : (
    <BasicLayout className="justify-center" displayNavElements={false}>
      <Footer>
        {error && <Button onClick={() => logout()}>GO BACK</Button>}
      </Footer>
    </BasicLayout>
  );
};

export default Login;
