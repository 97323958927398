import React, { ReactNode } from 'react';
import {
  desktopBGBuildingsLeft,
  desktopBGBuildingsRight,
  desktopBGCloudsLeft,
  desktopBGCloudsRight,
  desktopBGFloor,
} from 'src/assets/img';
import { useFeatureFlags, useModel } from 'src/lib/hooks';
import AnnouncementBar from '../AnnouncementBar';
import { links } from 'src/lib/enums';
import { twMerge } from 'src/lib/mergeTailwind';

interface ViewportProps {
  children: ReactNode;
}

const Viewport: React.FC<ViewportProps> = ({ children }) => {
  const { showMaintenanceTimeWarning, maintenanceMode } = useFeatureFlags();
  const { BindingRestriction } = useModel.quote();
  const { isLoading } = useModel.userInterface();

  const getBgClasses = () => {
    const noAnnouncement = !BindingRestriction && !showMaintenanceTimeWarning;
    const hasBinding = BindingRestriction && !showMaintenanceTimeWarning;
    const hasMaintenance = !BindingRestriction && showMaintenanceTimeWarning;
    const hasBoth = BindingRestriction && showMaintenanceTimeWarning;

    return [
      maintenanceMode || noAnnouncement ? '-bottom-0.5' : '',
      !maintenanceMode && (hasBinding || hasMaintenance) ? 'bottom-8' : '',
      !maintenanceMode && hasBoth ? 'bottom-24 md:bottom-16' : '',
    ].join(' ');
  };

  const BgClouds: React.FC<{ className?: string; fixedHeight: number }> = ({
    className,
    fixedHeight,
  }) => (
    <div
      className={twMerge('absolute top-16 w-full h-full', className)}
      style={{
        background: [
          `url("${desktopBGCloudsLeft}") no-repeat top left/auto ${fixedHeight}px`,
          `url("${desktopBGCloudsRight}") no-repeat top right/auto ${fixedHeight}px`,
          'linear-gradient(359deg, #FFF 29.99%, #F8F7FD 54.34%, #E8E4FA 87.15%)',
        ].join(','),
      }}
    />
  );

  const BgGround: React.FC<{ className?: string; fixedHeight: number }> = ({
    className,
    fixedHeight,
  }) => (
    <div
      className={twMerge('h-32 absolute w-full', getBgClasses(), className)}
      style={{
        background: [
          `url("${desktopBGFloor}") repeat-x bottom/auto ${fixedHeight}px`,
          `url("${desktopBGBuildingsRight}") no-repeat bottom right/auto ${fixedHeight}px`,
          `url("${desktopBGBuildingsLeft}") no-repeat bottom left/auto ${fixedHeight}px`,
          'linear-gradient(359deg, #FFF , #FFF )',
        ].join(','),
      }}
    />
  );

  const year = new Date().getFullYear();

  return (
    <div className="flex flex-col min-h-screen relative bg-spindle overflow-hidden h-viewport">
      {isLoading && (
        <div className="fixed w-full h-full z-30 opacity-0 inset-0 bg-mystic transition outline-none" />
      )}
      <BgClouds className="hidden lg:block" fixedHeight={400} />
      <BgGround className="hidden lg:block xl:hidden mb-16" fixedHeight={80} />
      <BgGround className="hidden xl:block mb-16" fixedHeight={128} />
      <div
        id="scrolling-container"
        className="xl:overflow-auto xl:relative h-full"
      >
        <header id="viewport-header" />
        <main className="flex-auto px-3 pt-0 md:px-6 xl:pt-6 sm:pb-10 lg:pb-20 lg:min-h-[90vh]">
          {children}
        </main>
      </div>
      <footer className="mb-10 w-full z-10">
        {!maintenanceMode && BindingRestriction && (
          <AnnouncementBar
            role="alert"
            className="bg-kournikova text-violet text-sm font-content"
            text={BindingRestriction.Message}
            showLink={true}
            textLink="Learn More"
            href="https://www.slideinsurance.com/binding-restriction"
            classNameLink="ml-1 underline "
          />
        )}
        {!maintenanceMode && showMaintenanceTimeWarning && (
          <AnnouncementBar
            role="alert"
            className="bg-yellow-500 text-violet text-sm"
            text="Our systems will begin routine maintenance within the next 10 minutes.
                You can resume your quote where you left off after the break."
          />
        )}
        <div className="w-full ml-0 space-x-3 flex flex-col-reverse content-center lg:flex-row lg:align-center lg:absolute lg:bottom-8 lg:ml-8">
          <div className="text-violet text-center lg:text-left text-2xs font-bold inline-block mt-3 lg:mt-1">
            {`©${year}, Slide Insurance Holdings, Inc. All rights reserved.`}{' '}
          </div>
          <div className="lg:inline-block flex justify-center space-x-6 lg:space-x-3 mt-3 lg:mt-0">
            <a
              href={links.privacyPolicy}
              target="_blank"
              rel="noreferrer"
              className="text-left align-middle text-violet text-2xs font-bold underline initial-flex inline-block"
            >
              Privacy Policy
            </a>{' '}
            <a
              href={links.termsOfUse}
              target="_blank"
              rel="noreferrer"
              className="text-left align-middle text-violet text-2xs font-bold underline initial-flex inline-block"
            >
              Terms of Service
            </a>{' '}
          </div>
        </div>
      </footer>
      <section id="viewport-loading-content" />
    </div>
  );
};

export default Viewport;
