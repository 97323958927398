import React, {
  ComponentPropsWithRef,
  useCallback,
  useRef,
  useState,
} from 'react';

import { Icon } from 'src/common';
import { twMerge } from 'src/lib/mergeTailwind';
import { useModel } from 'src/lib/hooks';
import { IconColor, IconName, IconSize } from '../Icon/Icon';

const iconSize = {
  small: 'sm',
  default: 'md',
  rectangular: 'base',
};

const loadingIconSize = {
  small: 'w-2 h-2',
  rectangular: 'w-5 h-5',
  default: 'w-5 h-5',
};

const iconColor = {
  'light': 'violet',
  'dark': 'white',
  'blue': 'mystic',
  'blue-light': '',
  'transparent': 'violet',
};

export type ColorType =
  | 'light'
  | 'dark'
  | 'blue'
  | 'blue-light'
  | 'transparent';
type IconSizeType = 'small' | 'rectangular' | 'default';

export type ButtonProps = {
  onClick?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  color?: ColorType;
  variant?: IconSizeType;
  disabled?: boolean;
  loading?: boolean;
  className?: string;
  labelClassName?: string;
  fontFamily?: string;
  iconLeft?: IconName;
  iconRight?: IconName;
  href?: string;
  target?: string;
  hideLabelWhenLoading?: boolean;
  blockScreenWhenLoading?: boolean;
} & ComponentPropsWithRef<'button'>;

const Button = ({
  onClick = () => {},
  color = 'light',
  variant = 'default',
  disabled = false,
  loading: _loading = false,
  children,
  className,
  labelClassName = '',
  fontFamily = 'font-content',
  iconLeft,
  iconRight,
  href,
  target,
  hideLabelWhenLoading = false,
  blockScreenWhenLoading = false,
  ...buttonProps
}: ButtonProps) => {
  const [loading, setLoading] = useState<boolean>(_loading);
  const onClickRef = useRef(onClick);
  const { setInterface } = useModel.userInterface.dispatch();
  onClickRef.current = onClick;

  const handleClick = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      try {
        setLoading(true);
        if (blockScreenWhenLoading) {
          setInterface({ isLoading: true });
        }
        await onClickRef.current(e);
      } finally {
        setLoading(false);
        if (blockScreenWhenLoading) {
          setInterface({ isLoading: false });
        }
      }
    },
    [setLoading, setInterface, blockScreenWhenLoading, onClickRef]
  );

  const isLoading = loading || _loading;
  const hideChildren = hideLabelWhenLoading && isLoading;
  const effectiveColor = isLoading ? 'dark' : color;
  let iconRightComp = iconRight && (
    <Icon
      name={iconRight}
      color={iconColor[effectiveColor] as IconColor}
      size={iconSize[variant] as IconSize}
    />
  );

  if (isLoading) {
    iconRightComp = (
      <Icon name="beachBall" className={loadingIconSize[variant]} />
    );
  }

  const buttonClassName = twMerge(
    effectiveColor === 'dark' &&
      'bg-violet text-white focus:ring-violet border-none',
    effectiveColor === 'light' && 'bg-white text-violet focus:ring-mystic',
    effectiveColor === 'blue-light' &&
      'bg-curious-blue-light text-violet focus:ring-violet',
    effectiveColor === 'blue' &&
      'bg-curious-blue-dark text-white border-curious-blue-dark focus:ring-mystic',
    effectiveColor === 'transparent' &&
      'bg-transparent text-violet focus:ring-violet border-none',
    variant === 'default' && 'xs:px-17px h-12 2xl:h-14 border-2',
    variant === 'small' && 'px-3 h-6',
    variant === 'rectangular' && 'py-3 px-5 rounded-xl text-sm space-x-2',
    variant !== 'rectangular' && 'rounded-full font-semibold space-x-1',
    'flex items-center font-heading transition',
    'focus:ring-2 focus:ring-offset-2 focus:outline-none focus:opacity-90',
    'disabled:cursor-not-allowed disabled:bg-input-lighter',
    className
  );

  const spanClassName = twMerge(
    fontFamily,
    'inline-block',
    variant === 'default' && 'text-base leading-4',
    variant === 'small' && 'text-xs 2xl:text-sm font-light',
    !iconLeft && !iconRight && 'mx-auto',
    !children && 'sr-only',
    labelClassName
  );

  const content = (
    <>
      {iconLeft && (
        <Icon
          name={iconLeft}
          color={iconColor[effectiveColor] as IconColor}
          size={iconSize[variant] as IconSize}
        />
      )}
      {!hideChildren && <span className={spanClassName}>{children}</span>}
      {iconRightComp}
    </>
  );

  return !href ? (
    <button
      disabled={isLoading || disabled}
      onClick={!isLoading && !disabled ? handleClick : () => {}}
      className={buttonClassName}
      {...buttonProps}
    >
      {content}
    </button>
  ) : (
    <a
      href={href}
      className={buttonClassName}
      target={target}
      onClick={onClick}
      rel={target === '_blank' ? 'noreferrer' : ''}
    >
      {content}
    </a>
  );
};

export default Button;
