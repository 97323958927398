import React, { useRef, useEffect, useCallback } from 'react';
import Card from './Card';
import { CardProps } from './Card/Card';
type ModalProps = { children?: React.ReactNode; ariaLabel: string } & CardProps;

const Modal = ({ children, ariaLabel = 'modal', ...cardProps }: ModalProps) => {
  const modalRef = useRef<HTMLDivElement | null>(null);
  const cardRef = useRef<HTMLDivElement | null>(null);

  const handleTabKey = useCallback((e: KeyboardEvent) => {
    if (modalRef.current) {
      const focusableModalElements = getFocusableElements();
      if (!focusableModalElements) {
        return;
      }
      const firstElement = focusableModalElements[0];
      const lastElement = focusableModalElements[
        focusableModalElements.length - 1
      ].disabled
        ? focusableModalElements[focusableModalElements.length - 2]
        : focusableModalElements[focusableModalElements.length - 1];

      if (
        document.activeElement &&
        !Array.from(focusableModalElements).includes(document.activeElement)
      ) {
        firstElement.focus();
        return e.preventDefault();
      }
      if (!e.shiftKey && document.activeElement === lastElement) {
        firstElement.focus();
        return e.preventDefault();
      }

      if (e.shiftKey && document.activeElement === firstElement) {
        lastElement.focus();
        return e.preventDefault();
      }
    }
  }, []);

  useEffect(() => {
    if (modalRef.current && cardRef.current) {
      cardRef.current.focus();
    }
  });

  useEffect(() => {
    const keyListener = (e: KeyboardEvent) => {
      const keyListenersMap = new Map([[9, handleTabKey]]);

      const listener = keyListenersMap.get(e.keyCode);
      return listener && listener(e);
    };

    document.addEventListener('keydown', keyListener);

    return () => document.removeEventListener('keydown', keyListener);
  }, [handleTabKey]);

  const getFocusableElements = () => {
    return (
      modalRef.current &&
      (modalRef.current.querySelectorAll(
        'a[href], a[onClick], button, textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select, [tabindex = "0"]'
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ) as any)
    );
  };

  return (
    <div
      role="dialog"
      ref={modalRef}
      aria-modal="true"
      aria-label={ariaLabel}
      className="transition duration-200 ease-in absolute w-full h-full inset-0 flex items-center justify-center "
    >
      <div className="fixed w-full h-full z-20 opacity-50 inset-0 bg-mystic transition outline-none"></div>
      <Card ref={cardRef} {...cardProps}>
        {children}
      </Card>
    </div>
  );
};

export default Modal;
