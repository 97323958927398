import { ComponentPropsWithRef, ReactNode } from 'react';
import { twMerge } from 'src/lib/mergeTailwind';

export type FieldSetVariant = 'default' | 'divider' | 'enclosed';

type FieldSetProps = {
  label?: ReactNode;
  labelId?: string;
  labelSrOnly: boolean;
  className?: string;
  variant: FieldSetVariant;
  error?: string | boolean;
} & ComponentPropsWithRef<'fieldset'>;

const Fieldset = ({
  label,
  labelId,
  labelSrOnly = false,
  children,
  className = '',
  variant = 'default',
  error,
  ...rest
}: FieldSetProps) => {
  return (
    <>
      <fieldset
        aria-describedby={label ? labelId : undefined}
        className={twMerge(
          'flex-1',
          (variant === 'divider' || variant === 'enclosed') &&
            'border-violet border-t relative transition',
          variant === 'enclosed' && 'border rounded-lg',
          variant === 'enclosed' && !!error && 'border-error',
          className
        )}
        {...rest}
      >
        {label && (
          <legend
            id={labelId}
            className={twMerge(
              variant === 'default' && 'text-center',
              (variant === 'divider' || variant === 'enclosed') &&
                'text-violet px-1 text-xs bg-white font-bold absolute -top-2 left-4 transition',
              labelSrOnly && 'sr-only',
              variant === 'enclosed' && !!error && 'text-error'
            )}
          >
            {label}
          </legend>
        )}
        {children}
      </fieldset>
      {variant === 'enclosed' && !!error && (
        <span className="text-error text-xs ml-2">{error}</span>
      )}
    </>
  );
};

export default Fieldset;
