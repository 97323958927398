import { ReactNode, useState } from 'react';
import { RefCallBack } from 'react-hook-form';
import { SimplifiedRadioInput, Icon } from 'src/common';
import { twMerge } from 'src/lib/mergeTailwind';

type CollapsibleCardProps = {
  value?: string | number;
  title: string;
  shortDescription: string;
  extendedDescription: string;
  img: string;
  disabled?: boolean;
  name: string;
  id?: string;
  onChange?: (event: unknown) => void;
  optionClassName?: string;
  selectedValue?: string | number;
  error?: string;
  inputRef?: RefCallBack;
  ChildElemnt?: ReactNode;
  inputClassName?: string;
};

const CollapsibleCard = ({
  value,
  title,
  shortDescription,
  extendedDescription,
  img,
  error,
  ...elementProps
}: CollapsibleCardProps) => {
  const [collapsed, setCollapsed] = useState(true);

  const ChildElemnt = (
    <>
      <div className="flex-column flex-1">
        <div className="font-bold text-violet text-base leading-[24px] mb-[-1px]">
          {title}
        </div>
        <div className="text-[#5b564d] text-qgrades">
          {collapsed ? shortDescription : extendedDescription}
        </div>
        {!collapsed && <img src={img} alt={title} className="mt-4" />}
      </div>
      <div className="align-top mt-1.5">
        <button
          type="button"
          className={twMerge('w-6', !collapsed ? 'rotate-180' : '')}
          onClick={() => setCollapsed(!collapsed)}
        >
          <Icon name="qGChevronDown" size="lg" />
        </button>
      </div>
    </>
  );

  return (
    <>
      <SimplifiedRadioInput.Option
        value={value}
        ChildElemnt={ChildElemnt}
        optionClassName="flex flex-row px-4 py-[18px] md:w-[548px] bg-[#f8f7fd] rounded-lg space-x-4"
        error={error}
        {...elementProps}
      />
    </>
  );
};

export default CollapsibleCard;
