import {
  GetPropsCommonOptions,
  UseComboboxGetItemPropsOptions,
  UseComboboxGetMenuPropsOptions,
} from 'downshift';
import React, { useState, useEffect, ReactNode } from 'react';
import { Modifier, usePopper } from 'react-popper';
import { twMerge } from 'src/lib/mergeTailwind';

type PlacementType = 'top' | 'bottom';

const offsetModifier = {
  name: 'offset',
  options: {
    offset: (options: { placement: PlacementType }) => {
      let yOffset = 0;
      if (options.placement === 'top') {
        yOffset = 37;
      } else if (options.placement === 'bottom') {
        yOffset = -8;
      }
      return [0, yOffset];
    },
  },
};
const classModifier: Modifier<'computeClasses'> = {
  name: 'computeClasses',
  enabled: true,
  phase: 'main',
  fn: ({ state }) => {
    const className =
      state.placement === 'top'
        ? 'border-t rounded-t-lg'
        : 'border-b rounded-b-lg z-50';
    state.attributes.popper = { ...state.attributes.popper, className };
    return state;
  },
};
const paddingModifier = {
  name: 'flip',
  options: {
    padding: { bottom: 85 },
  },
};

type DropdownMenuProps = {
  id?: string;
  isOpen?: boolean;
  disabled?: boolean;
  searching?: boolean;
  error?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: string[] | any[];
  highlightedIndex: number;
  getMenuProps: (
    options?: UseComboboxGetMenuPropsOptions,
    otherOptions?: GetPropsCommonOptions
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getItemProps: (options: UseComboboxGetItemPropsOptions<any>) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getItemValue?: (item: any) => string;
  extraOptionLi?: ReactNode;
  onBlur?: () => void;
  onFocus?: () => void;
  textClass?: string;
};

const DropdownMenu = ({
  id,
  isOpen = false,
  disabled,
  searching = false,
  error,
  options = [],
  highlightedIndex,
  getMenuProps,
  getItemProps,
  getItemValue,
  extraOptionLi = <></>,
  onBlur = () => {},
  onFocus = () => {},
  textClass = 'text-xs',
}: DropdownMenuProps) => {
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const { styles, attributes, update } = usePopper(
    referenceElement,
    popperElement,
    {
      modifiers: [offsetModifier, classModifier, paddingModifier],
    }
  );
  useEffect(() => {
    update && update();
  }, [options, update]);

  return (
    <div
      className="relative z-20"
      ref={(ref) => setReferenceElement(ref)}
      onBlur={onBlur}
      onFocus={onFocus}
      id={id}
    >
      <div
        ref={(ref) => setPopperElement(ref)}
        style={{
          ...styles.popper,
          left: 0,
          right: 0,
        }}
        {...attributes.popper}
        className={twMerge(
          isOpen && attributes?.popper?.className,
          'bg-white transition transform overflow-hidden',
          isOpen
            ? 'translate-y-0 opacity-100 border-l border-r'
            : '-translate-y-4 opacity-0',
          error ? 'border-error' : 'border-violet'
        )}
      >
        <ul
          {...getMenuProps()}
          className={twMerge(
            'max-h-32 mx-1 overflow-auto overscroll-auto custom-scrollbar focus:outline-none',
            isOpen && 'last:mb-1'
          )}
        >
          {isOpen && !disabled && searching && (
            <ul>
              <li className={twMerge(textClass, 'text-center text-input py-1')}>
                Searching...
              </li>
            </ul>
          )}
          {isOpen && !disabled && !searching && !options.length && (
            <li className={twMerge(textClass, 'text-center text-input p-1')}>
              {extraOptionLi}
            </li>
          )}
          {isOpen &&
            !disabled &&
            options.map((item, index) => {
              const itemValue = !!getItemValue ? getItemValue(item) : item;
              return (
                <li
                  key={`${itemValue}${index}`}
                  title={itemValue}
                  className={twMerge(
                    'cursor-default py-1 px-3 transition',
                    textClass,
                    highlightedIndex === index
                      ? 'bg-violet-lightest text-input'
                      : 'text-input-light',
                    item?.disabled ?? false
                      ? 'opacity-60 pointer-events-none'
                      : ''
                  )}
                  data-testid={`${id}.${itemValue}`}
                  {...getItemProps({ item, index })}
                >
                  {itemValue}
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

export default DropdownMenu;
