import { useHistory } from 'react-router-dom';
import { BasicLayout, Button, Card, Footer } from 'src/common';
import { useModel } from 'src/lib/hooks';

type RetryPageProps = {
  submit: (e: React.MouseEvent) => void;
  handleStartOver?: (e: React.MouseEvent) => void;
};

const RetryPage = ({ submit, handleStartOver }: RetryPageProps) => {
  const { signOut } = useModel.user.dispatch();
  const history = useHistory();

  const startOver = () => {
    signOut();
    history?.replace('/');
  };

  return (
    <BasicLayout>
      <Card
        icon="seashells"
        title="Aw, shucks!"
        subtitle="Something went wrong. Please try again."
      >
        <div className="flex justify-center">
          <Button
            color="dark"
            variant="rectangular"
            className="w-[142px] h-[42px] px-[17px] py-[9px] rounded-[22px] shadow border-2 justify-center items-center inline-flex text-base font-extrabold"
            onClick={submit}
          >
            Try Again
          </Button>
        </div>
      </Card>
      <Footer>
        <Footer.Back
          rounded
          hideIcon={true}
          label="Exit"
          onClick={handleStartOver ? handleStartOver : startOver}
          className="w-[103px] h-[51px] font-extrabold"
        />
      </Footer>
    </BasicLayout>
  );
};
export default RetryPage;
