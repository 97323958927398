import { useSelector, useDispatch } from 'react-redux';

const defaults = (optionsParams = {}) => {
  const options = { ignorePendingChanges: () => false, ...optionsParams };
  const { pageIndex, furthestIndex, pendingChanges } = useModel.session();
  const quote = useModel.quote();
  const isHistorical =
    pageIndex < furthestIndex && !options?.ignorePendingChanges(pendingChanges);

  if (isHistorical || options?.forceDefaults) {
    return pendingChanges;
  }
  if (options?.useServerDefaultValues) {
    return Object.assign({}, options?.initialValues, quote);
  }

  return Object.assign({}, options?.initialValues);
};

/* eslint-disable react-hooks/rules-of-hooks */
function createModelHook(key) {
  let model = () => useSelector((state) => state[key]);
  model.dispatch = () => useDispatch()[key];
  model.defaults = defaults;
  return model;
}

const useModel = {
  currentPhase: createModelHook('currentPhase'),
  quote: createModelHook('quote'),
  session: createModelHook('session'),
  user: createModelHook('user'),
  updated: createModelHook('updated'),
  userInterface: createModelHook('userInterface'),
};

export default useModel;
